<script>
import TemplateBase from '../TemplateBase';

export default defineNuxtComponent({
  components: { TemplateBase },
  props: {
    stepElement: {
      type: Object,
      required: false,
    },
    stepId: {
      type: Number,
      required: true,
    },
  },
});
</script>

<template>
  <TemplateBase
    :placeholder="__('Label text field')"
    :element="stepElement"
    :step-id="stepId"
  >
    <template #templateContent>
      <trailblazer-textfield
        hide-label
        expand
        disabled
        :placeholder="__('Enter text field here...')"
      />
    </template>
  </TemplateBase>
</template>
